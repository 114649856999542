import { Component, OnInit, Input, LOCALE_ID, Inject, ChangeDetectorRef } from '@angular/core';
import { PL } from 'src/lib/isell';
import { AbsModelloContenutoDialog, TipiChiusuraDialog, EsitoRichiestaChiusuraDialog, EventoComunicazioneDialog, UtilitaDialog, DatiComponenteParteDialog, GestioneDimensioniDialog, BottoniDialog, Utilita, DialogService, LoaderModaleService, ModelloChiusuraDialog, SelezioneValori, UtilitaDialogSelezioneValori, RigaModificabile, UtilitaDialogMessaggio, UtilitaTraduzioneData, UtilitaOperazioni } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { UtilitaCondiviseDispositivi } from 'src/app/utilita/pagine/condivise/utilitaCondiviseDispositivi';
import { DatiService, ModelloDispositivo, DatiRichiestiPerInserimentoEModificaDispositivo, InformazioniOperatore, ModelloSerieChiave } from 'src/app/servizi/dati.service';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';

export namespace DettagliDispositivo {
  export class DatiInizializzazione {
    constructor(
      public dispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo,
      public datiEDizionari: DatiRichiestiPerInserimentoEModificaDispositivo
    ) { }
  }

  export class DatiChiusura {
    constructor(public sonoStateEffettuateModifiche: boolean) { }
  }
}

export class ModelloInformazioniPrelievoPacchettiDispositivo {
  constructor(
    public dataRichiestaDato: Date = new Date(0),
    public dataRicezioneDato: Date = new Date(0),
    public dataUltimoPrelevamentoPacchetto: Date = null,
    public dataPacchettoMenoRecenteNonPrelevato: Date = null,
    public dataUltimaElaborazioneDatiInviatiDaDispositivo: Date = null
  ) { }
}

enum OperazioniModificaCampoDispositivo {
  DescrizioneDispositivo = 0,
  Email = 1,
  IDGruppoDispositivo = 2,
  IDLinguaDispositivo = 3,
  Operatori = 4,
  IDSerieChiaveDispositivo = 5,
  Note = 6
}

class DatiPerModifiche {
  constructor(
    public IDDispositivo: string,
    public DescrizioneDispositivo: string,
    public Email: string,
    public IDGruppoDispositivi: string,
    public IDLinguaDispositivo: string,
    public IDOperatori: string[],
    public IDSerieChiave: string,
    public Note: string
  ) {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(DescrizioneDispositivo)) {
      this.DescrizioneDispositivo = "";
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(Email)) {
      this.Email = "";
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(IDGruppoDispositivi)) {
      this.IDGruppoDispositivi = "";
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(IDLinguaDispositivo)) {
      this.IDLinguaDispositivo = "";
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(IDOperatori)) {
      this.IDOperatori = [];
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(IDSerieChiave)) {
      this.IDSerieChiave = "";//??
    }

    if (Utilita.FunzioniVarie.isUndefinedOrNull(Note)) {
      this.Note = "";
    }
  }

  clone(): DatiPerModifiche {
    return $.extend({}, this);
  }
}

class ModelloPerModifiche {
  private datiAttuali: DatiPerModifiche;
  public esistonoModifiche = false;

  constructor(
    protected servizioLoader: LoaderModaleService,
    protected servizioDialog: DialogService,
    protected servizioTraduzioni: TraduzioniService,
    protected servizioGestioneConnessioneHub: GestioneConnessioneHubService,
    private datiIniziali: DatiPerModifiche
  ) {
    this.datiAttuali = datiIniziali.clone();
  }

  get DescrizioneDispositivo(): string {
    return this.datiAttuali.DescrizioneDispositivo;
  }

  set DescrizioneDispositivo(DaImpostare: string) {
    this.datiAttuali.DescrizioneDispositivo = DaImpostare;
  }

  get IDGruppoDispositivi(): string {
    return this.datiAttuali.IDGruppoDispositivi;
  }

  set IDGruppoDispositivi(DaImpostare: string) {
    this.datiAttuali.IDGruppoDispositivi = DaImpostare;
  }

  get IDLinguaDispositivo(): string {
    return this.datiAttuali.IDLinguaDispositivo;
  }

  set IDLinguaDispositivo(DaImpostare: string) {
    this.datiAttuali.IDLinguaDispositivo = DaImpostare;
  }

  get IDSerieChiave(): string {
    return this.datiAttuali.IDSerieChiave;
  }

  set IDSerieChiave(DaImpostare: string) {
    this.datiAttuali.IDSerieChiave = DaImpostare;
  }

  get Email(): string {
    return this.datiAttuali.Email;
  }

  set Email(DaImpostare: string) {
    this.datiAttuali.Email = DaImpostare;
  }

  get IDOperatori(): string[] {
    return this.datiAttuali.IDOperatori;
  }

  set IDOperatori(DaImpostare: string[]) {
    this.datiAttuali.IDOperatori = DaImpostare;
  }

  get Note(): string {
    return this.datiAttuali.Note;
  }

  set Note(DaImpostare: string) {
    this.datiAttuali.Note = DaImpostare;
  }

  get operazione_DescrizioneDispositivo(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.DescrizioneDispositivo;
  }

  get operazione_IDGruppoDispositivo(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.IDGruppoDispositivo;
  }

  get operazione_IDLinguaDispositivo(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.IDLinguaDispositivo;
  }

  get operazione_IDSerieChiaveDispositivo(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.IDSerieChiaveDispositivo;
  }

  get operazione_Email(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.Email;
  }

  get operazione_Operatori(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.Operatori;
  }

  get operazione_Note(): OperazioniModificaCampoDispositivo {
    return OperazioniModificaCampoDispositivo.Note;
  }

  onRichiestaConfermaModifica(operazione: OperazioniModificaCampoDispositivo, richiesta: RigaModificabile.WrapperRichiesta): void {
    var UUIDLoader = this.servizioLoader.mostraLoader();

    this.servizioGestioneConnessioneHub.verificaConnessioneORiavvia().done(() => {
      var hub = $.connection.hubGestioneDispositivi.server;
      var IDDispositivoIniziale = this.datiIniziali.IDDispositivo;

      var promise: JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<any>>;

      var messaggioErrore = null;

      var gestisciRispostaConErroreBase = (risultato: PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>) => {
        if (risultato.Esito == PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.Fallimento) {
          switch (risultato.ErroreFallimento) {
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase.CampoRichiesto: {
              messaggioErrore = this.servizioTraduzioni.traduci("272_VALORE_RICHIESTO_PER_IL_CAMPO_CHE_SI_STA_TENTANDO_DI_MODIFICARE");
              break;
            }
          }
        }
      }

      switch (operazione) {
        case OperazioniModificaCampoDispositivo.DescrizioneDispositivo: {
          promise = hub.modificaDescrizioneDispositivo(IDDispositivoIniziale, this.DescrizioneDispositivo).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.IDGruppoDispositivo: {
          promise = hub.modificaGruppoDispositivo(IDDispositivoIniziale, this.IDGruppoDispositivi).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.IDLinguaDispositivo: {
          promise = hub.modificaLinguaDispositivo(IDDispositivoIniziale, this.IDLinguaDispositivo).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.IDSerieChiaveDispositivo: {
          promise = hub.modificaSerieChiaveDispositivo(IDDispositivoIniziale, this.IDSerieChiave).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.Email: {
          promise = hub.modificaEmailDispositivo(IDDispositivoIniziale, this.Email).done((risultato) => {
            if (risultato.Esito == PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.Fallimento) {
              switch (risultato.ErroreFallimento) {
                case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoEmail.EmailNonValida: {
                  messaggioErrore = this.servizioTraduzioni.traduci("18_FORMATO_EMAIL_NON_VALIDO");
                  break;
                }
              }
            }
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.Operatori: {
          promise = hub.modificaOperatoriDispositivo(IDDispositivoIniziale, this.IDOperatori).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
        case OperazioniModificaCampoDispositivo.Note: {
          promise = hub.modificaNoteDispositivo(IDDispositivoIniziale, this.Note).done((risultato) => {
            gestisciRispostaConErroreBase(risultato);
          });
          break;
        }
      }

      var successo = false;

      promise.done((risultato) => {
        switch (risultato.Esito) {
          case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.Successo: {
            successo = true;
            this.esistonoModifiche = true;
            break;
          }
          case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.ErroreNonGestito: {
            messaggioErrore = this.servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE");
            break;
          }
          case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.ErroreDispositivoNonTrovato: {
            messaggioErrore = this.servizioTraduzioni.traduci("62_INFORMAZIONI_DISPOSITIVO_NON_TROVATE");
            break;
          }
          case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.ErroreSalvataggio: {
            messaggioErrore = this.servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE");
            break;
          }
          case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo.Fallimento: {
            if (Utilita.FunzioniVarie.isUndefinedOrNull(messaggioErrore)) {
              messaggioErrore = this.servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE");
            }
            break;
          }
        }
      }).fail(() => {
        messaggioErrore = this.servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE");
      }).always(() => {
        if (successo) {
          this.confermaModificaCampo(operazione);
          richiesta.conferma();
        }
        else {
          UtilitaDialogMessaggio.messaggioErroreSoloOK(
            this.servizioDialog,
            this.servizioTraduzioni,
            messaggioErrore
          );
          richiesta.annullaOFallisci();
        }
        this.servizioLoader.nascondiLoader(UUIDLoader);
      })
    }).fail(() => {
      UtilitaDialogMessaggio.messaggioErroreSoloOK(
        this.servizioDialog,
        this.servizioTraduzioni,
        this.servizioTraduzioni.traduci("188_IMPOSSIBILE_STABILIRE_LA_CONNESSIONE")
      )
    }).always(() => {
      this.servizioLoader.nascondiLoader(UUIDLoader);
    })
  }

  private confermaModificaCampo(operazione: OperazioniModificaCampoDispositivo) {
    this.modificaCampo(operazione, true);
  }

  annullaModificaCampo(operazione: OperazioniModificaCampoDispositivo) {
    this.modificaCampo(operazione, false);
  }

  private modificaCampo(operazione: OperazioniModificaCampoDispositivo, conferma: boolean) {
    var oggettoDaCuiPrelevare = conferma ? this.datiAttuali : this.datiIniziali;
    var oggettoACuiAssegnare = conferma ? this.datiIniziali : this.datiAttuali;

    switch (operazione) {
      case OperazioniModificaCampoDispositivo.DescrizioneDispositivo: {
        oggettoACuiAssegnare.DescrizioneDispositivo = oggettoDaCuiPrelevare.DescrizioneDispositivo;
        break;
      }
      case OperazioniModificaCampoDispositivo.IDGruppoDispositivo: {
        oggettoACuiAssegnare.IDGruppoDispositivi = oggettoDaCuiPrelevare.IDGruppoDispositivi;
        break;
      }
      case OperazioniModificaCampoDispositivo.IDLinguaDispositivo: {
        oggettoACuiAssegnare.IDLinguaDispositivo = oggettoDaCuiPrelevare.IDLinguaDispositivo;
        break;
      }
      case OperazioniModificaCampoDispositivo.IDSerieChiaveDispositivo: {
        oggettoACuiAssegnare.IDSerieChiave = oggettoDaCuiPrelevare.IDSerieChiave;
        break;
      }
      case OperazioniModificaCampoDispositivo.Note: {
        oggettoACuiAssegnare.Note = oggettoDaCuiPrelevare.Note;
        break;
      }
      case OperazioniModificaCampoDispositivo.Operatori: {
        oggettoACuiAssegnare.IDOperatori = oggettoDaCuiPrelevare.IDOperatori;
        break;
      }
      case OperazioniModificaCampoDispositivo.Email: {
        oggettoACuiAssegnare.Email = oggettoDaCuiPrelevare.Email;
        break;
      }
    }
  }
}

@Component({
  selector: 'app-dialog-dettagli-dispositivo',
  templateUrl: './dialog-dettagli-dispositivo.component.html',
  styleUrls: ['./dialog-dettagli-dispositivo.component.less']
})
export class DialogDettagliDispositivoComponent extends AbsModelloContenutoDialog<DettagliDispositivo.DatiChiusura> implements OnInit {

  @Input() datiInizializzazione: DettagliDispositivo.DatiInizializzazione;

  datiModificabili: ModelloPerModifiche;

  labelAbilitazione: string = "";
  testoAbilitazione: string = "";
  datiPacchetto: ModelloInformazioniPrelievoPacchettiDispositivo = new ModelloInformazioniPrelievoPacchettiDispositivo();
  private modelloPerSelettoreOperatori: SelezioneValori.ModelloStrutturaSelettore<string, InformazioniOperatore>;

  constructor(
    protected servizioDati: DatiService,
    protected servizioTraduzioni: TraduzioniService,
    ref: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string,
    protected servizioLoader: LoaderModaleService,
    protected servizioDialog: DialogService,
    protected servizioGestioneConnessioneHub: GestioneConnessioneHubService
  ) {
    super(ref);
  }

  get dispositivo() {
    return this.datiInizializzazione.dispositivo;
  }

  get datiEDizionari() {
    return this.datiInizializzazione.datiEDizionari;
  }

  ngOnInit() {
    this.datiModificabili = new ModelloPerModifiche(
      this.servizioLoader,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioGestioneConnessioneHub,
      new DatiPerModifiche(
        this.dispositivo.IDDispositivo,
        this.dispositivo.DescrizioneDispositivo,
        this.dispositivo.Email,
        this.dispositivo.IDGruppoDispositivi,
        this.dispositivo.IDLinguaDispositivo,
        this.dispositivo.IDOperatori,
        this.dispositivo.IDSerieChiave,
        this.dispositivo.Note
      )
    );

    if (this.dispositivo.TipoAbilitazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.AbilitatoDurante) {
      var labelAbilitazioneDaTradurre: string;
      var dataAbilitazione: string;

      if (this.dispositivo.Attivo) {
        if (Utilita.FunzioniVarie.isDefined(this.dispositivo.DataFineAbilitazione)) {
          labelAbilitazioneDaTradurre = "69_ABILITATO_FINO_AL";
          dataAbilitazione = this.dispositivo.DataFineAbilitazione;
        }
        else {
          labelAbilitazioneDaTradurre = "123_ABILITATO_DAL";
          dataAbilitazione = this.dispositivo.DataInizioAbilitazione;
        }
      }
      else {
        if (Utilita.FunzioniVarie.isDefined(this.dispositivo.DataFineAbilitazione) && this.isPastDate(this.dispositivo.DataFineAbilitazione)) {
          labelAbilitazioneDaTradurre = "70_DISABILITATO_IN_DATA";
          dataAbilitazione = this.dispositivo.DataFineAbilitazione;
        }
        else {
          labelAbilitazioneDaTradurre = "68_ABILITATO_A_PARTIRE_DAL";
          dataAbilitazione = this.dispositivo.DataInizioAbilitazione;
        }
      }

      if (Utilita.FunzioniVarie.isDefined(labelAbilitazioneDaTradurre)
        && Utilita.FunzioniVarie.isDefined(dataAbilitazione)) {
        this.labelAbilitazione = this.servizioTraduzioni.traduci(labelAbilitazioneDaTradurre);

        this.testoAbilitazione = UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          dataAbilitazione,
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData("166_OGGI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData("165_IERI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData(null, "mediumDate"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData(null, "mediumDate")
        );
      }
    }

    if (!this.dispositivo.DispositivoTemporaneo)
      this.rilevaStatoPrelievoPacchetti(this.dispositivo.IDDispositivo);
  }

  mostraSelettoreOperatori(richiesta: RigaModificabile.WrapperRichiesta) {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(this.modelloPerSelettoreOperatori)) {
      this.modelloPerSelettoreOperatori = UtilitaCondiviseDispositivi.generaModelloSelettoreOperatori(
        this.datiEDizionari.dizionarioOperatori,
        this.servizioTraduzioni
      );
    }

    this.modelloPerSelettoreOperatori.elencoIDValoriSelezionati.clear();
    this.datiModificabili.IDOperatori.forEach((IDOperatore) => {
      this.modelloPerSelettoreOperatori.elencoIDValoriSelezionati.add(IDOperatore);
    })

    var opzioniDialog = UtilitaDialogSelezioneValori.generaDialogSelezioneValoriConConfigurazioniDefault(
      this.modelloPerSelettoreOperatori,
      this.servizioTraduzioni.traduci("7_SELEZIONA_OPERATORI"),
      (modelloChiusura: ModelloChiusuraDialog<SelezioneValori.DatiChiusura>) => {
        if (modelloChiusura.confermato) {
          this.modelloPerSelettoreOperatori.elencoIDValoriSelezionati = modelloChiusura.datiChiusura.elencoIDValoriSelezionati;
          this.datiModificabili.IDOperatori = modelloChiusura.datiChiusura.elencoIDValoriSelezionati.toArray();
          richiesta.conferma();
        }
        else {
          richiesta.annullaOFallisci();
        }
      },
      this.servizioTraduzioni,
      this.servizioTraduzioni.traduci("52_TUTTI_GLI_OPERATORI")
    );

    this.servizioDialog.aggiungiDialog(opzioniDialog);
  }

  private rilevaStatoPrelievoPacchetti(IDDispositivo: string): void {
    try {
      this.datiPacchetto.dataRichiestaDato = new Date();

      $.connection.hubGestioneDispositivi.server.rilevaStatoPrelievoPacchetti(IDDispositivo).done(
        (risultato: PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoRilevazioneStatoPrelievoPacchetti) => {
          this.datiPacchetto.dataUltimoPrelevamentoPacchetto = Utilita.FunzioniVarie.isUndefinedOrNull(risultato.DataUltimoPrelevamentoPacchetto) ? null : new Date(risultato.DataUltimoPrelevamentoPacchetto as string);
          this.datiPacchetto.dataPacchettoMenoRecenteNonPrelevato = Utilita.FunzioniVarie.isUndefinedOrNull(risultato.DataPacchettoMenoRecenteNonPrelevato) ? null : new Date(risultato.DataPacchettoMenoRecenteNonPrelevato as string);
          this.datiPacchetto.dataUltimaElaborazioneDatiInviatiDaDispositivo = Utilita.FunzioniVarie.isUndefinedOrNull(risultato.DataUltimaElaborazioneDatiInviatiDaDispositivo) ? null : new Date(risultato.DataUltimaElaborazioneDatiInviatiDaDispositivo as string);
          this.datiPacchetto.dataRicezioneDato = new Date();

          this.detectChangesInContestoAsincrono();
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  richiediChiusura(tipoChiusura: TipiChiusuraDialog): Promise<EsitoRichiestaChiusuraDialog<DettagliDispositivo.DatiChiusura>> {
    return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(true, new DettagliDispositivo.DatiChiusura(this.datiModificabili.esistonoModifiche)));
  }

  isPastDate = (dateAsString: string): boolean => {
    return new Date(dateAsString) < new Date();
  }

  operatoriToString(IDOpetatori: string[]): string {
    return UtilitaCondiviseDispositivi.operatoriToString(IDOpetatori, this.servizioDati);
  }

  private formattaDataGiornoSemplice(data: string) {
    return UtilitaTraduzioneData.formattaData(
      this.locale,
      this.servizioTraduzioni,
      data,
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("160_OGGI_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("159_IERI_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("254_GIORNO_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("255_DATA_ALLE_ORA", "mediumDate")
    );
  }

  formattaDataAggiornamentoDispositivo(): string {
    return this.formattaDataGiornoSemplice(this.datiPacchetto.dataUltimoPrelevamentoPacchetto.toISOString());
  }

  formattaDataAggiornamentiInAttesaDiPrelievo(): string {
    return UtilitaTraduzioneData.formattaData(
      this.locale,
      this.servizioTraduzioni,
      this.datiPacchetto.dataPacchettoMenoRecenteNonPrelevato.toISOString(),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("256_DA_OGGI_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("257_DA_IERI_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("258_DA_GIORNO_ALLE_ORA"),
      new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("259_DAL_DATA_ALLE_ORA", "mediumDate")
    );
  }

  formattaDataUltimaRicezioneInviataDalDispositivo(): string {
    return this.formattaDataGiornoSemplice(this.datiPacchetto.dataUltimaElaborazioneDatiInviatiDaDispositivo.toISOString());
  }

  ottieniClasseColoreSerieChiave(serieChiave: ModelloSerieChiave = null): string {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(serieChiave)) {
      serieChiave = this.datiEDizionari.dizionarioSerieChiavi.getValue(this.datiModificabili.IDSerieChiave);
    }

    return UtilitaCondiviseDispositivi.ottieniClasseColoreSelectOOptionSerieChiave(serieChiave);
  }

  ottieniNomeOptionSerieChiave(serieChiave: ModelloSerieChiave): string {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(serieChiave)) {
      serieChiave = this.datiEDizionari.dizionarioSerieChiavi.getValue(this.datiModificabili.IDSerieChiave);
    }

    return UtilitaCondiviseDispositivi.ottieniNomeOptionSerieChiave(this.servizioTraduzioni, serieChiave);
  }

  ottieniNomeOptionSerieChiaveSemplice(serieChiave: ModelloSerieChiave): string {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(serieChiave)) {
      serieChiave = this.datiEDizionari.dizionarioSerieChiavi.getValue(this.datiModificabili.IDSerieChiave);
    }

    if (Utilita.FunzioniVarie.isDefined(serieChiave)) {
      return serieChiave.DescrizioneSerie;
    }
    else {
      return "";
    }
  }

  protected onComunicazioneDaComponenteDialog(comunicazione: EventoComunicazioneDialog): void { }

  static nuovoDialog(
    dispositivo: ModelloDispositivo,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    servizioLoader: LoaderModaleService,
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    servizioDati: DatiService
  ): void {

    var gestisciFallimentoMostraDialog = () => {
      UtilitaOperazioni.gestisciFallimentoMostraDialogOperazione(
        servizioDialog,
        servizioTraduzioni,
        (modelloChiusura) => {
          if (modelloChiusura.confermato) {
            DialogDettagliDispositivoComponent.nuovoDialog(
              dispositivo,
              servizioDialog,
              servizioTraduzioni,
              servizioLoader,
              servizioGestioneConnessioneHubs,
              servizioDati);
          }
        }
      )
    }

    if (Utilita.FunzioniVarie.isDefined(dispositivo)) {
      var UUIDLoader = servizioLoader.mostraLoader();

      servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
        servizioDati.richiediDatiPerInserimentoEModificaDispositivo().done((datiRichiesti) => {

          //Creo una copia in modo che i dati modificati esternamente non influiscano sulla compilazione del form
          var clone = {} as DatiRichiestiPerInserimentoEModificaDispositivo;
          $.extend(clone, datiRichiesti);

          var opzioniDialog = UtilitaDialog.generaOpzioniDialogDefault<DettagliDispositivo.DatiChiusura, DialogDettagliDispositivoComponent>(
            new DatiComponenteParteDialog<DialogDettagliDispositivoComponent>(
              DialogDettagliDispositivoComponent,
              <DialogDettagliDispositivoComponent>{
                datiInizializzazione: new DettagliDispositivo.DatiInizializzazione(dispositivo, datiRichiesti)
              }
            ),
            servizioTraduzioni.traduci("72_DETTAGLI_DISPOSITIVO"),
            (modelloChisura: ModelloChiusuraDialog<DettagliDispositivo.DatiChiusura>) => {
              if (modelloChisura.datiChiusura.sonoStateEffettuateModifiche) {
                servizioDati.ricaricaDatiPerPaginaAttuale();
              }
            },
            new GestioneDimensioniDialog(),
            BottoniDialog.Singolo_Annulla,
            null,
            servizioTraduzioni.traduci("73_CHIUDI"),
            true,
            false
          );

          opzioniDialog.stickyFooter = false;

          servizioDialog.aggiungiDialog(opzioniDialog);
        }).fail((errore) => {
          console.error(errore);
          gestisciFallimentoMostraDialog();
        }).always(() => {
          servizioLoader.nascondiLoader(UUIDLoader);
        });
      }).fail(() => {
        servizioLoader.nascondiLoader(UUIDLoader);
        gestisciFallimentoMostraDialog();
      });
    }
  }

}
