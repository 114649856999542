import { DatiService, TipiRichiesteRecuperoDatiPerPagina, ModelloDatiElaborazione, PaginaConRicaricaDatiWebApp } from "src/app/servizi/dati.service";
import { ChangeDetectorRef, ElementRef, NgZone } from "@angular/core";
import { VisualizzazionePaginaConGruppiEFiltroStato, VoceSelezionabilePerFiltroStato } from "src/app/utilita/modelliPagina";
import { VisualizzazioneGruppi, LoaderModaleService, Utilita, DialogService, UtilitaTraduzioneData, UtilitaOperazioni, ModelliRecuperoDati } from "@pl-web-angular/libreria";
import { TraduzioniService } from "src/app/servizi/traduzioni.service";
import { UtenteConnessoService } from "src/app/servizi/utente-connesso.service";
import { PL } from "src/lib/isell";
import { formatDate } from "@angular/common";
import { DialogDettagliDispositivoComponent } from "../dialog-dettagli-dispositivo/dialog-dettagli-dispositivo.component";
import { IconaDispositivoPreferitoService } from "../../icona-dispositivo-preferito/icona-dispositivo-preferito.service";
import { Subscriber, Subscription } from "rxjs";
import { GestioneConnessioneHubService } from "src/app/servizi/gestione-connessione-hub.service";
import { DialogDettagliPacchettoComponent } from "../dialog-dettagli-pacchetto/dialog-dettagli-pacchetto.component";

type ModelloDatiPacchettoElaborazione = PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiPacchettoElaborazione;

export abstract class ModelloPaginaInvioORicezioneDatiComponent extends PaginaConRicaricaDatiWebApp<VisualizzazionePaginaInvioORicezioneDati> {

  private subscriberModificaPreferito: Subscription;

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    protected tipoRichiestaPerPagina: TipiRichiesteRecuperoDatiPerPagina,
    NgZone: NgZone,
    protected servizioLoader: LoaderModaleService,
    protected servizioTraduzioni: TraduzioniService,
    protected servizioUtenteConnesso: UtenteConnessoService,
    protected locale: string,
    protected elm: ElementRef,
    protected servizioDialog: DialogService,
    protected servizioIconaDispositivoPreferito: IconaDispositivoPreferitoService,
    protected servizioGestioneConnessioneHubs: GestioneConnessioneHubService
  ) {
    super(
      ref,
      servizioDati,
      NgZone
    );

    this.visualizzazioneGruppi = new VisualizzazionePaginaInvioORicezioneDati(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      locale,
      servizioDati);

    this.subscriberModificaPreferito = this.servizioIconaDispositivoPreferito.onModificaPreferito.subscribe((IDDispositivo: string) => {
      this.visualizzazioneGruppi.riordinaGruppiConDispositivo(IDDispositivo);
    });

    this.inizializzaPaginaConRicaricaDati(
      [this.tipoRichiestaPerPagina, TipiRichiesteRecuperoDatiPerPagina.Dispositivi]
    )
  }

  caricaDati(modelloStati: ModelliRecuperoDati.ModelloStatiRecuperoDatiPagina): void {
    if (modelloStati.statoUltimaRichiesta == ModelliRecuperoDati.StatiRichiestaRecuperoDati.TerminataConSuccesso) {
      if (this.tipoRichiestaPerPagina == TipiRichiesteRecuperoDatiPerPagina.InvioDati) {
        this.visualizzazioneGruppi.modificaElaborazioni(this.servizioDati.datiInvioDati.listaElaborazioni)
      }
      else {
        this.visualizzazioneGruppi.modificaElaborazioni(this.servizioDati.datiRicezioneDati.listaElaborazioni)
      }
    }
  }

  protected _onInitCallback() {
    $(this.elm.nativeElement).addClass(
      [
        this.tipoRichiestaPerPagina == TipiRichiesteRecuperoDatiPerPagina.InvioDati ? "paginaDatiInviati" : "paginaDatiRicevuti",
        "paginaDatiInvitiatiORicevuti"
      ])
  }

  protected _onDestroyCallback() {
    super.ngOnDestroy();
    this.subscriberModificaPreferito.unsubscribe();
  }

  visualizzaDettagliDispositivo(IDDispositivo: string) {
    DialogDettagliDispositivoComponent.nuovoDialog(
      this.servizioDati.dispositivoDaDizionarioLocale(IDDispositivo).dati,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioLoader,
      this.servizioGestioneConnessioneHubs,
      this.servizioDati
    );
  }

  get tipoPaginaUgualeInvioDati(): boolean {
    return this.tipoRichiestaPerPagina == TipiRichiesteRecuperoDatiPerPagina.InvioDati;
  }

  get tipoPaginaUgualeRicezioneDati(): boolean {
    return this.tipoRichiestaPerPagina == TipiRichiesteRecuperoDatiPerPagina.RicezioneDati;
  }

  codiceStringaPerStatoPacchetto(datiPacchetto: ModelloDatiPacchettoElaborazione): string {
    if (this.tipoPaginaUgualeInvioDati) {
      return Utilita.FunzioniVarie.stringIsNullOrEmpty(datiPacchetto.DataOraPrelevamento) ? "inattesa" : "prelevato";
    }
    return "";
  }

  messaggioPerStatoPacchetto(datiPacchetto: ModelloDatiPacchettoElaborazione): string {
    if (this.tipoPaginaUgualeInvioDati) {
      if (Utilita.FunzioniVarie.stringIsNullOrEmpty(datiPacchetto.DataOraPrelevamento)) {
        return this.servizioTraduzioni.traduci("250_IN_ATTESA_DI_PRELEVAMENTO");
      }
      else {
        return UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          datiPacchetto.DataOraPrelevamento,
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("260_PRELEVATO_OGGI_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("261_PRELEVATO_IERI_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("262_PRELEVATO_GIORNO_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("263_PRELEVATO_IL_DATA_ALLE_ORA", "mediumDate"),
        )
      }
    }
    return "";
  }

  visualizzaDettagliPacchetto(pacchetto: ModelloDatiPacchettoElaborazione) {
    var gestisciFallimento = () => {
      UtilitaOperazioni.gestisciFallimentoMostraDialogOperazione(
        this.servizioDialog,
        this.servizioTraduzioni,
        () => {
          this.visualizzaDettagliPacchetto(pacchetto);
        }
      )
    }

    var UUIDLoader = this.servizioLoader.mostraLoader();

    this.servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
      $.connection.hubGestioneInvioERicezioneDati.server.rilevaDettagliContenutoPacchetto(pacchetto.NomeFilePacchetto)
        .done((modelloPerDescrizionePacchetto: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloPerDescrizionePacchetto) => {
          DialogDettagliPacchettoComponent.nuovoDialog(
            pacchetto,
            modelloPerDescrizionePacchetto,
            this.servizioDialog,
            this.servizioTraduzioni
          );
        }).fail((errore) => {
          console.error(errore);
          gestisciFallimento();
        }).always(() => {
          this.servizioLoader.nascondiLoader(UUIDLoader);
        });
    }).fail(() => {
      this.servizioLoader.nascondiLoader(UUIDLoader);
      gestisciFallimento();
    });
  }
}

enum FiltriStatiPacchetti {
  Nessuno = 0,
  InAttesaDiPrelevamento = 1,
  Prelevati = 2
}

class PeriodoRicercaSelezionabile {
  constructor(public giorni: number, public descrizione: string) { }
}

class VisualizzazionePaginaInvioORicezioneDati
  extends VisualizzazionePaginaConGruppiEFiltroStato<
    Date,
    string,
    ModelloDatiPacchettoElaborazione,
    ModelloDatiPacchettoElaborazione,
    VisualizzazioneGruppi.WrapperDatiEsteso,
    FiltriStatiPacchetti> {

  static readonly NUMERO_GIORNI_MASSIMI_RICHIESTI = 30;

  dataAttualeAdUltimoAggiornamentoDati: Date = new Date();
  periodoDiRicercaInGiorni: number = 15;
  periodiDiRicercaInGiorniSelezionabili: PeriodoRicercaSelezionabile[] = [];

  private dizionarioGruppiPerDispositivi: Utilita.Dizionario<string, Utilita.Set<string>> = new Utilita.Dizionario<string, Utilita.Set<string>>();

  constructor(
    ref: ChangeDetectorRef,
    servizioLoader: LoaderModaleService,
    servizioTraduzioni: TraduzioniService,
    servizioUtenteConnesso: UtenteConnessoService,
    protected locale: string,
    protected servizioDati: DatiService
  ) {

    super(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Nessuno,
      new VisualizzazioneGruppi.ConfigurazioniVirtualScroll(true)
    );

    this.periodiDiRicercaInGiorniSelezionabili.push(new PeriodoRicercaSelezionabile(1, this.servizioTraduzioni.traduci("279_OGGI")));
    this.periodiDiRicercaInGiorniSelezionabili.push(new PeriodoRicercaSelezionabile(7, this.servizioTraduzioni.traduci("280_SETTIMANA")));
    this.periodiDiRicercaInGiorniSelezionabili.push(new PeriodoRicercaSelezionabile(15, this.servizioTraduzioni.traduci("281_ULTIMI_15_GIORNI")));
    this.periodiDiRicercaInGiorniSelezionabili.push(new PeriodoRicercaSelezionabile(VisualizzazionePaginaInvioORicezioneDati.NUMERO_GIORNI_MASSIMI_RICHIESTI, this.servizioTraduzioni.traduci("282_ULTIMI_30_GIORNI")));

    this.vociSelezionabiliPerFiltroStato.push(
      new VoceSelezionabilePerFiltroStato(FiltriStatiPacchetti.Nessuno, this.servizioTraduzioni.traduci("6_TUTTI")))
    this.vociSelezionabiliPerFiltroStato.push(
      new VoceSelezionabilePerFiltroStato(FiltriStatiPacchetti.InAttesaDiPrelevamento, this.servizioTraduzioni.traduci("283_IN_ATTESA")))
    this.vociSelezionabiliPerFiltroStato.push(
      new VoceSelezionabilePerFiltroStato(FiltriStatiPacchetti.Prelevati, this.servizioTraduzioni.traduci("284_PRELEVATI")))
  }

  modificaElaborazioni(listaElaborazioni: ModelloDatiElaborazione[]) {
    var _formatDataPerCompare = (data: string | number | Date): string => {
      return formatDate(data, "yyyyMMddHHmmss", this.locale);
    }

    this.dizionarioGruppiPerDispositivi.clear();
    this.dataAttualeAdUltimoAggiornamentoDati = new Date();

    var elencoGruppi: VisualizzazioneGruppi.ModelloGruppo<Date, string>[] = [];
    var elencoVoci: VisualizzazioneGruppi.ModelloVoce<ModelloDatiPacchettoElaborazione, ModelloDatiPacchettoElaborazione>[] = [];

    listaElaborazioni.forEach((elaborazione) => {
      var dataGiornata = new Date(elaborazione.DataGiornata);
      var dataEOraChiave = _formatDataPerCompare(dataGiornata);

      elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo(
        dataEOraChiave,
        dataGiornata,
        dataEOraChiave,
        UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          elaborazione.DataGiornata,
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData("166_OGGI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData("165_IERI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData(null, "mediumDate"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloData(null, "mediumDate")
        ),
        ""
      ))

      elaborazione.ListaDati.forEach((datiPacchetto) => {
        elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
          datiPacchetto.NomeFilePacchetto + '_' + datiPacchetto.DataElaborazione,
          datiPacchetto,
          datiPacchetto,
          datiPacchetto.NomeFilePacchetto,
          "",
          dataEOraChiave
        ))
      })
    })

    this.modificaGruppiEVoci(
      elencoGruppi,
      elencoVoci,
      (IDGruppo: string, modelloGruppo: VisualizzazioneGruppi.ModelloGruppo<Date, string>) => {
        return new VisualizzazioneGruppi.WrapperDatiEsteso(IDGruppo)
      },
      VisualizzazioneGruppi.ConfigurazioneOrdinamento.nuovaConfigurazioneAvanzata(
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.DESC,
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.ASC,
        VisualizzazioneGruppi.ConfigurazioneOrdinamento.ordinamentoStandard,
        (modello1, modello2) => {
          var datiOrd1 = modello1.elementoDiOrdinamento;
          var datiOrd2 = modello2.elementoDiOrdinamento;
          if (datiOrd1.DataElaborazione != datiOrd2.DataElaborazione) {
            var data1Formattata = _formatDataPerCompare(datiOrd1.DataElaborazione);
            var data2Formattata = _formatDataPerCompare(datiOrd2.DataElaborazione);
            return (-1) * data1Formattata.localeCompare(data2Formattata);
          }

          var Dispositivo1 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd1.IDDispositivo).dati;
          var Dispositivo2 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd2.IDDispositivo).dati;
          var Preferito1 = Utilita.FunzioniVarie.isDefined(Dispositivo1) ? Dispositivo1.Preferito : false;
          var Preferito2 = Utilita.FunzioniVarie.isDefined(Dispositivo2) ? Dispositivo2.Preferito : false;

          if (Preferito1 && !Preferito2) {
            return -1;
          }
          else if (!Preferito1 && Preferito2) {
            return 1;
          }
          else {
            return modello1.elementoDiOrdinamento.DescrizioneDispositivo.localeCompare(modello2.elementoDiOrdinamento.DescrizioneDispositivo);
          }
        },
      ),
      (gruppo, datiGruppo, numeroTotaleElementi) => {
        var dataNelMedesimoGiorno = Utilita.FunzioniVarie.dateNelMedesimoGiorno(datiGruppo.dati, this.dataAttualeAdUltimoAggiornamentoDati);
        gruppo.gruppoVisibile = dataNelMedesimoGiorno;
        gruppo.gruppoVisibileDuranteRicerca = dataNelMedesimoGiorno;
      },
      this.onSingolaAggiuntaVoce.bind(this)
    );
  }

  private onSingolaAggiuntaVoce(IDGruppo: string, voce: VisualizzazioneGruppi.ModelloVoce<ModelloDatiPacchettoElaborazione, ModelloDatiPacchettoElaborazione>) {
    var IDDispositivo = voce.dati.IDDispositivo;
    if (!this.dizionarioGruppiPerDispositivi.containsKey(IDDispositivo)) {
      this.dizionarioGruppiPerDispositivi.setValue(IDDispositivo, new Utilita.Set<string>());
    }
    this.dizionarioGruppiPerDispositivi.getValue(IDDispositivo).add(IDGruppo);
  }

  esisteFiltroAttivo(): boolean {
    return this._esisteFiltroTesto
      || this._esisteFiltroStato
      || this._esisteFiltroPeriodo
  }

  private get _esisteFiltroTesto(): boolean {
    return this.filtroRicerca.length > 0;
  }

  private get _esisteFiltroStato(): boolean {
    return this.voceSelezionataPerFiltroStato != FiltriStatiPacchetti.Nessuno;
  }

  private get _esisteFiltroPeriodo(): boolean {
    return this.periodoDiRicercaInGiorni != VisualizzazionePaginaInvioORicezioneDati.NUMERO_GIORNI_MASSIMI_RICHIESTI;
  }

  protected _callbackModificaFiltroAttivo_Pre() {
  }
  protected _callbackModificaFiltroAttivo_Post() {
  }

  protected _applicaFiltroDiRicerca(IDGruppo: string,
    elementoDaValutare: VisualizzazioneGruppi.ModelloVoce<ModelloDatiPacchettoElaborazione, ModelloDatiPacchettoElaborazione>): boolean {

    var filtraPerTesto = (): boolean => {
      if (this._esisteFiltroTesto) {
        var filtro = this.filtroRicerca.toLowerCase();

        return ((elementoDaValutare.dati.IDDispositivo.toLowerCase().indexOf(filtro) != -1) ||
          (elementoDaValutare.dati.DescrizioneDispositivo.toLowerCase().indexOf(filtro) != -1));
      }
      else {
        return true;
      }

    }

    var verificaSePacchettoCorrispondeAStato = (): boolean => {
      if (this._esisteFiltroStato) {
        var oraPrelevamentoDefinita = !Utilita.FunzioniVarie.stringIsNullOrEmpty(elementoDaValutare.dati.DataOraPrelevamento);

        return (
          (this.voceSelezionataPerFiltroStato == FiltriStatiPacchetti.Nessuno)
          || (!oraPrelevamentoDefinita && this.voceSelezionataPerFiltroStato == FiltriStatiPacchetti.InAttesaDiPrelevamento)
          || (oraPrelevamentoDefinita && this.voceSelezionataPerFiltroStato == FiltriStatiPacchetti.Prelevati)
        );
      }
      else {
        return true;
      }
    }

    var verificaModelloVisualizzabilePerPeriodo = (): boolean => {
      if (this._esisteFiltroPeriodo) {
        if (Utilita.FunzioniVarie.isDefined(IDGruppo)) {
          var DataGiornata = this.rilevaDatiDaDizionarioOrdinato(IDGruppo) as Date;
          return (DataGiornata >= new Date(this.dataAttualeAdUltimoAggiornamentoDati.getTime() - (this.periodoDiRicercaInGiorni * 86400000)))
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }
    }

    return verificaSePacchettoCorrispondeAStato() && filtraPerTesto() && verificaModelloVisualizzabilePerPeriodo();
  }

  protected _callbackModificaFiltroStato_Pre() {
  }
  protected _callbackModificaFiltroStato_Post() {
    this.ref.detectChanges();
  }

  callbackModificaPeriodoDiRicerca() {
    this.aggiornaListeValoriFiltrati();
    this.ref.detectChanges();
  }

  riordinaGruppiConDispositivo(IDDispositivo: string) {
    var elencoGruppi = this.dizionarioGruppiPerDispositivi.getValue(IDDispositivo);
    if (Utilita.FunzioniVarie.isDefined(elencoGruppi)) {
      elencoGruppi.forEach((IDGruppo: string) => {
        this.riordinaVociGruppo(IDGruppo, false, false);
      });
      this.aggiornaElencoOrdinatoFiltrato();
      this.ref.detectChanges();
    }
  }
}
