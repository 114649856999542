export namespace PL {
    export namespace iSell {
        export namespace Console {
            export interface StatoElaborazioneArchivio {
                DescrizioneAttivita: string;
                DescrizioneAzione: string;
                DescrizioneDispositivo: string;
                ElaborazioneInCorso: boolean;
                IDDispositivo: string;
            }
            
            export namespace Console {
                export enum TipiIstanzaConsole {
                    Servizio = 0,
                    Manager = 1,
                }
                
            }
            export namespace Gestione {
                export namespace Archivio {
                    export namespace GestioneEstensioniArchivioConsole {
                        export interface DatiEstensione {
                            DescrizioneAddIn: string;
                            ElencoAvvisi: string[];
                            Errore: PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.ErroriEstensione;
                            IDEstensione: string;
                            NomeCartellaEstensione: string;
                            PercorsoCompletoCartellaEstensione: string;
                            PercorsoOrigineFile: string;
                            StatoEstensione: PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.StatiEstensione;
                            VersioneAddIn: string;
                        }
                        
                        export enum ErroriEstensione {
                            Nessuno = 0,
                            CartellaEstensioneNonPresente = 1,
                            ImpossibileIndividuareAddInInCartellaEstensione = 2,
                            IndividuatiAddInMultipliInCartellaEstensione = 3,
                            IDEstensioneDiversoDaEstensioneRegistrata = 4,
                            ErroreDuranteAggiornamentoCacheAddIn = 5,
                            ErroreDuranteRilevamentoAddIn = 6,
                        }
                        
                        export enum ErroriRegistrazioneEdAggiornamentoEstensione {
                            Nessuno = 0,
                            FileNonTrovato = 1,
                            FileNonConsonoPerUtilizzoComeEstensione = 2,
                            ErroreGenerico = 3,
                            EsisteGiaUnEstensioneConLoStessoIdentificatoreRegistrata = 4,
                            IdentificatoreEstensioneNonCorrispondeAdIdentificatoreEstensioneGiaRegistrata = 5,
                        }
                        
                        export enum ErroriRimozioneEstensione {
                            Nessuno = 0,
                            ErroreGenerico = 1,
                            ErroreCaricamentoEstensionePerEliminazioneDatiGenerati = 2,
                            ErroreEliminazioneDatiGenerati = 3,
                            ErroreDuranteLaRimozioneEstensioneDaDatabaseArchivio = 4,
                            ErroreDuranteLaRimozioneDeiFileAusiliari = 5,
                        }
                        
                        export enum StatiEstensione {
                            Disabilitata = 0,
                            Abilitata = 1,
                        }
                        
                    }
                }
            }
            export namespace ModelliComunicazioneSignalR {
                export enum RisultatoOperazioneLogin {
                    NessunErrore = 0,
                    PasswordNonSpecificata = 1,
                    CredenzialiNonValide = 2,
                    ErroreLogin = 3,
                    NomeUtenteNonSpecificato = 4,
                    NomeArchivioNonSpecificato = 5,
                }
                
            }
            export namespace Processi {
                export interface ProcessoConsole {
                    IDProcesso: number;
                    TipoIstanzaConsole: PL.iSell.Console.Console.TipiIstanzaConsole;
                }
                
            }
            export namespace ServerWeb {
                export namespace Hubs {
                    export namespace HubGestioneAttivita {
                        export interface ModelloAttivitaSemplificato {
                            AttivitaGestisceCanali: boolean;
                            AttivitaGestisceDispositivi: boolean;
                            DescrizioneAttivita: string;
                            IDAttivita: string;
                            IDCanaliTrasmissione: string[];
                            IDGruppoAttivita: string;
                            StatoAttivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita;
                            TipoAttivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita;
                            TipoSelezioneDispositiviAvvioAttivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita;
                        }
                        
                        export interface ModelloDispositivo {
                            DescrizioneDispositivo: string;
                            IDDispositivo: string;
                            Selezionato: boolean;
                        }
                        
                        export interface ModelloGruppoAttivita {
                            DescrizioneGruppoAttivita: string;
                            IDGruppoAttivita: string;
                        }
                        
                        export interface ModelloGruppoDispostivi {
                            DescrizioneGruppoDispositivi: string;
                            Dispositivi: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloDispositivo[];
                            IDGruppoDispositivi: string;
                        }
                        
                        export interface ModelloInformazioniBasePianificazioneAttivita {
                            DescrizionePianificazioneAttivita: string;
                            IDPianificazioneAttivita: string;
                        }
                        
                        export interface ModelloStatoAttivita {
                            IDAttivita: string;
                            StatoAttivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita;
                        }
                        
                        export enum StatiAttivita {
                            NonAttiva = 0,
                            InCoda = 1,
                            InElaborazione = 2,
                            NonDisponibile = 3,
                        }
                        
                        export enum TipiAttivita {
                            Sconosciuto = 0,
                            PreparazioneDatiPerDispositivi = 1,
                            ElaborazioneDatiDaDispositivi = 2,
                            InvioDatiADispositivi = 3,
                            RicezioneDatiDaDispositivi = 4,
                            OperazioniGeneriche = 5,
                            InvioScriptADispositivi = 6,
                        }
                        
                        export enum TipiSelezioneDispositiviAvvioAttivita {
                            NessunaSelezione = 0,
                            Selezione = 1,
                            SelezioneDisattivata = 2,
                            SelezioneSingoloDispositivo = 3,
                        }
                        
                        export enum TipologiaErroreAvvioAttivita {
                            NessunErrore = 0,
                            ErroreDispositiviMancanti = 1,
                            ErroreAvvioAttivita = 2,
                            ErroreMultipliDispositiviSuSelezioneSingola = 3,
                            ErroreNessunCanalePredefinitoAssociatoAdAttivita = 4,
                            ErroreCanaleAssociatoAdAttivitaNonTrovato = 5,
                        }
                        
                    }
                    export namespace HubGestioneDispositivi {
                        export enum ErroriModificaCampoDispositivoEmail {
                            Nessuno = 0,
                            EmailNonValida = 1,
                        }
                        
                        export enum ErroriModificaCampoDispositivoErroriBase {
                            Nessuno = 0,
                            CampoRichiesto = 1,
                        }
                        
                        export enum ErroriPostValidazioneCreazioneDispositivo {
                            ErroreIDDispositivoGiaAssegnato = 0,
                            ErroreIDHardwareGiaAssociato = 1,
                            ErroreIDHardwareNonValido = 2,
                        }
                        
                        export enum ErroriPostValidazioneRipristinoBackup {
                            Nessuno = 0,
                            DispositivoNonTrovato = 1,
                        }
                        
                        export enum ErroriValidazioneCreazioneDispositivo {
                            EmailNonSpecificata = 0,
                            EmailNonValida = 1,
                            ErroreDescrizioneDispositivoNonSpecificata = 2,
                            ErroreSerieChiaveNonSpecificata = 3,
                            ErroreIDDispositivoNonSpecificato = 4,
                            ErroreIDHardwareNonSpecificato = 5,
                        }
                        
                        export enum ErroriValidazioneRipristinoBackup {
                            EmailNonSpecificata = 0,
                            EmailNonValida = 1,
                            IDDispositivoNonSpecificato = 2,
                            NomeFileBackupNonSpecificato = 3,
                        }
                        
                        export enum EsitiModificaCampoDispositivo {
                            Successo = 0,
                            Fallimento = 1,
                            ErroreNonGestito = 2,
                            ErroreDispositivoNonTrovato = 3,
                            ErroreSalvataggio = 4,
                        }
                        
                        export interface RisultatoModificaCampoDispositivo<ENUMERRORIFALLIMENTO> {
                            ErroreFallimento: ENUMERRORIFALLIMENTO;
                            Esito: PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.EsitiModificaCampoDispositivo;
                        }
                        
                        export enum RisultatoOperazioneEliminaDispositivoEdInvito {
                            NessunErrore = 0,
                            InformazioniDispositivoNonTrovate = 1,
                        }
                        
                        export interface RisultatoRilevazioneStatoPrelievoPacchetti {
                            DataPacchettoMenoRecenteNonPrelevato: string;
                            DataUltimaElaborazioneDatiInviatiDaDispositivo: string;
                            DataUltimoPrelevamentoPacchetto: string;
                        }
                        
                    }
                    export namespace HubGestioneElementiImportati {
                        export interface DettagliDocumentoConInformazioniCorpo {
                            Dettagli: PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniDettagliateDocumentoConDescrizioni;
                            Righe: PL.iSell.Console.ServerWeb.Hubs.HubGestioneElementiImportati.InformazioniRigaDocumentoConDescrizioni[];
                        }
                        
                        export interface InformazioniRigaDocumentoConDescrizioni {
                            AliquotaIva: string;
                            DataConsegna: string;
                            DescrizioneAliquotaIva: string;
                            DescrizioneArticolo: string;
                            DescrizioneCausaleRigaDocumento: string;
                            DescrizioneDeposito: string;
                            DescrizioneEsterno: string;
                            DescrizioneListino: string;
                            DescrizioneLotto: string;
                            DescrizioneRiga: string;
                            DescrizioneSezioneDocumento: string;
                            DescrizioneTipoConsegna: string;
                            DescrizioneUnitaDiMisura: string;
                            DescrizioneVarianteArticolo: string;
                            IDArticolo: string;
                            IDCausaleRigaDocumento: string;
                            IDDeposito: string;
                            IdentificatoreVisualeTipoCausale: number;
                            IDEsterno: string;
                            IDListino: string;
                            IDLotto: string;
                            IDRigaDocumento: string;
                            IDSezioneDocumento: string;
                            IDTipoConsegna: string;
                            IDVarianteArticolo: string;
                            ImponibileIva: string;
                            ImportoValorizzazione: string;
                            Note: string;
                            PrezzoLordo: string;
                            PrezzoNetto: string;
                            Quantita: string;
                            Sconto1: string;
                            Sconto2: string;
                            Sconto3: string;
                            Sconto4: string;
                            Sconto5: string;
                            Sconto6: string;
                            Sconto7: string;
                            Sconto8: string;
                            ScontoValore: string;
                            StringaSconti: string;
                            TipoValorizzazionePrezzoQuantita: PL.iSell.Utilita.ValoriTabelle.RigheDocumenti.TipoValorizzazionePrezzoQuantita;
                            ValoreInformazione: string;
                        }
                        
                    }
                    export namespace HubGestioneInvioERicezioneDati {
                        export interface ModelloDatiElaborazione {
                            AvviataManualmente: boolean;
                            DataGiornata: string;
                            ListaDati: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiPacchettoElaborazione[];
                        }
                        
                        export interface ModelloDatiPacchettoElaborazione {
                            DataElaborazione: string;
                            DataOraPrelevamento: string;
                            DescrizioneDispositivo: string;
                            DescrizioneErrori: string;
                            DescrizionePacchetto: string;
                            EsisteDispositivoCollegato: boolean;
                            IDDispositivo: string;
                            NomeFilePacchetto: string;
                            PresenzaErrori: boolean;
                        }
                        
                        export interface ModelloPerDescrizionePacchetto {
                            ModelliPerDescrizionePacchettoVociDettagliate: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloPerDescrizionePacchettoVociDettagliate[];
                            NumeroElementiElaboratiConDescrizione: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloADueValori<number, string>[];
                        }
                        
                        export interface ModelloPerDescrizionePacchettoVociDettagliate {
                            TitoloSezione: string;
                            VociDettagliate: string[];
                        }
                        
                        export enum TipiRichiestaElaborazioni {
                            InvioDati = 0,
                            RicezioneDati = 1,
                        }
                        
                    }
                    export namespace HubGestioneInvitiDispositivi {
                        export enum ErroriPostValidazioneNuovoInvitoDispositivo {
                            ErroreChiaveDeviceNonDisponibile = 0,
                        }
                        
                        export enum ErroriValidazioneNuovoInvitoDispositivo {
                            EmailNonSpecificata = 0,
                            EmailNonValida = 1,
                            ErroreDescrizioneDispositivoNonSpecificata = 2,
                            ErroreSerieChiaveNonSpecificata = 3,
                        }
                        
                        export interface ModelloInvitoDispositivo {
                            DataOraInvito: string;
                            DataOraRegistrazione: string;
                            Email: string;
                            IDDispositivo: string;
                        }
                        
                    }
                    export namespace HubLogin {
                        export interface DatiUtente {
                            Descrizione: string;
                            ElencoPermessiUtente: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitoConValorePermesso[];
                            IDArchivio: string;
                        }
                        
                        export interface InformazioniPerLogin {
                            DescrizioneArchivioRichiesta: string;
                            SupportaArchiviMultipli: boolean;
                        }
                        
                        export interface ModelloDatiSessioneUtenteConnesso {
                            DataScadenzaSessione: string;
                            DatiUtente: PL.iSell.Console.ServerWeb.Hubs.HubLogin.DatiUtente;
                            DescrizioneArchivio: string;
                            ElencoLinkAusiliariArchivio: PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloLinkAusiliarioArchivio[];
                            IDArchivio: string;
                            PresenzaLogoArchivio: boolean;
                            SezioniAbilitate: PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili[];
                        }
                        
                        export interface ModelloLinkAusiliarioArchivio {
                            DescrizioneLink: string;
                            ForzaAperturaEsterna: boolean;
                            IDLink: string;
                            Ordinamento: number;
                            ValoreLink: string;
                        }
                        
                    }
                    export namespace Modelli {
                        export interface InformazioniAnagrafica {
                            DescrizioneAnagrafica: string;
                            IDAnagrafica: string;
                        }
                        
                        export interface InformazioniDettagliateAnagrafica {
                            Cap: string;
                            Citta: string;
                            DescrizioneAnagrafica: string;
                            IDAnagrafica: string;
                            Indirizzo: string;
                            Provincia: string;
                        }
                        
                        export interface InformazioniDettagliateDocumento
                            extends PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniGeneraliDocumento {
                            Acconto: string;
                            AliquotaIva: string;
                            Colli: string;
                            DataConsegna: string;
                            IDAnagraficaRiferimento: string;
                            IDCicloEmissioneDocumenti: string;
                            IDDeposito: string;
                            IdentificatoreDati: string;
                            IDEsterno: string;
                            IDListino: string;
                            IDPagamento: string;
                            IDTipoConsegna: string;
                            IDTipoSpedizione: string;
                            IDTipoTrasporto: string;
                            IDValuta: string;
                            ImponibileValorizzazione: string;
                            IvaValorizzazione: string;
                            Note: string;
                            OmaggiValorizzazione: string;
                            RegioneOrigineDati: string;
                            ScontoChiusura1: string;
                            ScontoChiusura2: string;
                            ScontoChiusura3: string;
                            TipoValorizzazionePrezzi: PL.iSell.Utilita.ValoriTabelle.Documenti.TipoValorizzazionePrezzi;
                            TotaleValorizzazione: string;
                            Trasmissione: PL.iSell.Utilita.ValoriTabelle.Trasmissione;
                        }
                        
                        export interface InformazioniDettagliateDocumentoConDescrizioni
                            extends PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniDettagliateDocumento {
                            DescrizioneAliquotaIva: string;
                            DescrizioneAnagraficaDestinatario: PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniDettagliateAnagrafica;
                            DescrizioneAnagraficaIntestatario: PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniDettagliateAnagrafica;
                            DescrizioneAnagraficaRiferimento: PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniDettagliateAnagrafica;
                            DescrizioneCicloEmissioneDocumenti: string;
                            DescrizioneDeposito: string;
                            DescrizioneEsterno: string;
                            DescrizioneListino: string;
                            DescrizioneOperatoreOrigineDati: string;
                            DescrizionePagamento: string;
                            DescrizioneTipoConsegna: string;
                            DescrizioneTipoDocumento: string;
                            DescrizioneTipoSpedizione: string;
                            DescrizioneTipoTrasporto: string;
                            DescrizioneValuta: string;
                        }
                        
                        export interface InformazioniGeneraliDocumento {
                            DataDocumento: string;
                            DataOrigineDati: string;
                            IDAnagraficaDestinatario: string;
                            IDAnagraficaIntestatario: string;
                            IDDispositivoOrigineDati: string;
                            IDDocumento: string;
                            IdentificatoreVisualeStatoDocumento: PL.iSell.Utilita.ValoriTabelle.IdentificatoreVisuale;
                            IDOperatoreOrigineDati: string;
                            IDTipoDocumento: string;
                            NumeroDocumento: number;
                            PrefissoDocumento: string;
                            StatoDocumento: PL.iSell.Utilita.ValoriTabelle.Documenti.StatoDocumento;
                        }
                        
                        export interface InformazioniGeneraliDocumentoConDescrizioni
                            extends PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniGeneraliDocumento {
                            DescrizioneAnagraficaDestinatario: string;
                            DescrizioneAnagraficaIntestatario: string;
                            DescrizioneDispositivoOrigineDati: string;
                            DescrizioneOperatoreOrigineDati: string;
                            DescrizioneTipoDocumento: string;
                        }
                        
                        export interface InformazioniOperatore {
                            DescrizioneOperatore: string;
                            IDOperatore: string;
                        }
                        
                        export interface InformazioniTipoDocumento {
                            DescrizioneTipoDocumento: string;
                            IDTipoDocumento: string;
                        }
                        
                        export interface ModelloADueValori<K, V> {
                            PrimoValore: K;
                            SecondoValore: V;
                        }
                        
                        export interface ModelloDatiPacchettoBackupDispositivo {
                            DataBackup: string;
                            DataRegistrazione: string;
                            DescrizioneDispositivoOrigine: string;
                            IDDispositivoOrigine: string;
                            IDHDispositivoOrigine: string;
                            NomeFile: string;
                        }
                        
                        export interface ModelloDispositivo {
                            Attivo: boolean;
                            DataFineAbilitazione: string;
                            DataInizioAbilitazione: string;
                            DescrizioneDispositivo: string;
                            DispositivoTemporaneo: boolean;
                            Email: string;
                            IDDispositivo: string;
                            IDGruppoDispositivi: string;
                            IDHardware: string;
                            IDLinguaDispositivo: string;
                            IDOperatori: string[];
                            IDSerieChiave: string;
                            InformazioniDispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloInformazioniDispositivo;
                            Note: string;
                            PossiedeChiave: PL.iSell.Console.ServerWeb.Hubs.Modelli.ValoreConStatoElaborazione<boolean>;
                            Preferito: boolean;
                            TipoAbilitazione: PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo;
                        }
                        
                        export interface ModelloGruppoDispositivi {
                            DescrizioneGruppoDispositivi: string;
                            IDGruppoDispositivi: string;
                        }
                        
                        export interface ModelloInformazioniDispositivo {
                            DataOraAggiornamentoInformazioniDispositivo: string;
                            DatiPresenti: boolean;
                            IDNotifiche: string;
                            PrimoInvioEseguito: boolean;
                            VersioneApplicativo: string;
                        }
                        
                        export interface ModelloLingua {
                            Descrizione: string;
                            ID: string;
                            UgualeALinguaArchivio: boolean;
                        }
                        
                        export interface ModelloSerieChiave {
                            ChiaveTest: boolean;
                            DataInizioValidita: string;
                            DescrizioneSerie: string;
                            IDSerie: string;
                            NumeroChiaviDeviceDisponibili: number;
                            Predefinita: boolean;
                            Stato: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave.StatiChiave;
                        }
                        
                        export interface ModelloUtenteSemplificato {
                            DescrizioneUtente: string;
                            IDUtente: string;
                        }
                        
                        export interface RisultatoEsitoOperazione {
                            EsitoOperazione: PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione;
                        }
                        
                        export interface RisultatoOperazioneConErroriSeparati<TIPOERRORI_VALIDAZIONE, TIPOERRORE_POSTVALIDAZIONE>
                            extends PL.iSell.Console.ServerWeb.Hubs.Modelli.RisultatoOperazioneConErroriValidazione<TIPOERRORI_VALIDAZIONE> {
                            Errore: PL.iSell.Console.ServerWeb.Hubs.Modelli.ValoreImpostabile<TIPOERRORE_POSTVALIDAZIONE>;
                        }
                        
                        export interface RisultatoOperazioneConErroriValidazione<TIPOERRORI_VALIDAZIONE>
                            extends PL.iSell.Console.ServerWeb.Hubs.Modelli.RisultatoEsitoOperazione {
                            ErroriValidazione: TIPOERRORI_VALIDAZIONE[];
                        }
                        
                        export enum TipiAbilitazioneDispositivo {
                            Disabilitato = 0,
                            Abilitato = 1,
                            AbilitatoDurante = 2,
                        }
                        
                        export enum TipiEsitoOperazione {
                            Successo = 0,
                            Fallimento = 1,
                            ErroreNonGestito = 2,
                        }
                        
                        export interface ValoreConStatoElaborazione<T> {
                            Elaborato: boolean;
                            Valore: T;
                        }
                        
                        export interface ValoreImpostabile<T> {
                            Impostato: boolean;
                            Valore: T;
                        }
                        
                        export namespace ModelloSerieChiave {
                            export enum StatiChiave {
                                Attesa = 0,
                                Valida = 1,
                                InScadenza = 2,
                                Scaduta = 3,
                            }
                            
                        }
                    }
                }
                export namespace InformazioniServerWebConsole {
                    export enum SezioniAbilitabili {
                        Attivita = 0,
                        Dispositivi = 1,
                        InvitiDispositivi = 2,
                        Estensioni = 3,
                        Documenti = 4,
                        StatoGenerale = 5,
                    }
                    
                }
                export namespace Moduli {
                    export namespace UtilitaPermessi {
                        export enum AmbitiPermessoServerWeb {
                            Nessuno = 0,
                            Attivita = 1,
                            Chiavi = 2,
                            Dispositivi = 3,
                            Configurazione = 4,
                            Utenti = 5,
                            Gestione = 6,
                            Notifiche = 7,
                        }
                        
                        export interface AmbitoConValorePermesso {
                            Ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb;
                            Valore: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb;
                        }
                        
                        export enum ValoriPermessoServerWeb {
                            Nessuno = 0,
                            Lettura = 1,
                            Modifica = 2,
                        }
                        
                    }
                }
            }
        }
        export namespace Utilita {
            export namespace ValoriTabelle {
                export enum IdentificatoreVisuale {
                    Nessuno = 0,
                    Verde = 1,
                    Giallo = 2,
                    Rosso = 3,
                    Blu = 4,
                    Arancio = 5,
                    Azzurro = 6,
                    Viola = 7,
                    Marrone = 8,
                    Rosa = 9,
                    Nero = 10,
                }
                
                export enum Trasmissione {
                    DaNonTrasmettere = 0,
                    DaTrasmettere = 1,
                }
                
                export namespace Documenti {
                    export enum StatoDocumento {
                        Chiuso = 0,
                        Aperto = 1,
                        Annullato = 2,
                        Invalidato = 3,
                    }
                    
                    export enum TipoValorizzazionePrezzi {
                        PrezzoNonIvato = 0,
                        PrezzoIvato = 1,
                    }
                    
                }
                export namespace RigheDocumenti {
                    export enum TipoValorizzazionePrezzoQuantita {
                        PrezzoMoltiplicatoPerLaQuantità = 0,
                        PrezzoMoltiplicatoPerLaQuantitàEPerIlCoefficienteQuantità = 1,
                    }
                    
                }
            }
        }
    }
}

interface HubConsoleWindows {
    server: HubConsoleWindowsServer;
    client: HubConsoleWindowsClient;
}

interface HubConsoleWindowsServer {
    confermaSessioneAttivaLatoClient(IDProcesso: number): JQueryPromise<void>;
    rilevaStatoElaborazioneArchivio(IDArchivio: string): JQueryPromise<PL.iSell.Console.StatoElaborazioneArchivio>;
    rilevaStatoConnessioneArchivio(IDArchivio: string, IDConnessione: string): JQueryPromise<boolean>;
    terminaSessione(IDProcesso: number): JQueryPromise<void>;
    rilevaProcessiAttivi(): JQueryPromise<PL.iSell.Console.Processi.ProcessoConsole[]>;
    rilevaSeServizioInElaborazione(): JQueryPromise<boolean>;
}

interface HubConsoleWindowsClient {
    richiediChiusuraConsoleClient(): void;
}

interface HubEstensioni {
    server: HubEstensioniServer;
    client: HubEstensioniClient;
}

interface HubEstensioniServer {
    registraEstensione(percorsoFile: string): JQueryPromise<PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.ErroriRegistrazioneEdAggiornamentoEstensione>;
    aggiornaEstensione(IDEstensione: string, percorsoFile: string): JQueryPromise<PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.ErroriRegistrazioneEdAggiornamentoEstensione>;
    modificaStatoEstensione(IDEstensione: string, statoDaApplicare: PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.StatiEstensione): JQueryPromise<boolean>;
    rimuoviEstensione(IDEstensione: string, eseguiEliminazioneDatiGenerati: boolean): JQueryPromise<PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.ErroriRimozioneEstensione>;
    rilevaElencoEstensioni(): JQueryPromise<PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.DatiEstensione[]>;
    rilevaEstensione(IDEstensione: string): JQueryPromise<PL.iSell.Console.Gestione.Archivio.GestioneEstensioniArchivioConsole.DatiEstensione>;
}

interface HubEstensioniClient {
}

interface HubGestioneAttivita {
    server: HubGestioneAttivitaServer;
    client: HubGestioneAttivitaClient;
}

interface HubGestioneAttivitaServer {
    rilevaAttivitaConStato(soloAttive: boolean): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato[]>;
    rilevaStatoAttivita(IDsAttivita: string[]): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloStatoAttivita[]>;
    rilevaElencoGruppiAttivita(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloGruppoAttivita[]>;
    avviaAttivita(IDAttivita: string, IDDispositiviSelezionati: string[]): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita>;
    rilevaDispositiviPerAttivita(IDAttivita: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloGruppoDispostivi[]>;
    rilevaElencoAttivitaPianificate(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloInformazioniBasePianificazioneAttivita[]>;
    avviaPianificazioneAttivita(IDPianificazioneAttivita: string): JQueryPromise<boolean>;
}

interface HubGestioneAttivitaClient {
}

interface HubGestioneDispositivi {
    server: HubGestioneDispositiviServer;
    client: HubGestioneDispositiviClient;
}

interface HubGestioneDispositiviServer {
    rilevaDispositivi(soloAttivi: boolean): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo[]>;
    rilevaLingueDisponibili(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloLingua[]>;
    rilevaGruppiDisponibili(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloGruppoDispositivi[]>;
    rilevaOperatoriDisponibili(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniOperatore[]>;
    confermaCreazioneDispositivo(IDDispositivo: string, emailDispositivo: string, operatoriDaAssociare: string[], descrizioneDispositivo: string, IDGruppoDispositivi: string, IDLingua: string, IDSerieChiave: string, IDHardware: string, note: string, preferito: boolean): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.RisultatoOperazioneConErroriSeparati<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo, PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneCreazioneDispositivo>>;
    eliminaDispositivoEdInvitoDispositivo(IDDispositivo: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoOperazioneEliminaDispositivoEdInvito>;
    rilevaStatoPrelievoPacchetti(IDDispositivo: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoRilevazioneStatoPrelievoPacchetti>;
    rilevaChiaviDisponibili(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave[]>;
    rilevaIDDispositivoValido(): JQueryPromise<string>;
    aggiornaERilevaStatoPreferitoPerDispositivo(IDDispositivo: string, preferito: boolean): JQueryPromise<boolean>;
    accodaRicezioneBackupDispositivi(uuidOperazionePerNotifica: string): JQueryPromise<void>;
    rilevaBackupDisponibili(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDatiPacchettoBackupDispositivo[]>;
    sostituisciORipristinaDispositivo(sostituzione: boolean, IDDispositivo: string, nomeFile: string, email: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.RisultatoOperazioneConErroriSeparati<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneRipristinoBackup, PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneRipristinoBackup>>;
    rilevaDispositivo(IDDispositivo: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo>;
    modificaDescrizioneDispositivo(IDDispositivo: string, nuovoDescrizione: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
    modificaGruppoDispositivo(IDDispositivo: string, nuovoIdGruppo: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
    modificaLinguaDispositivo(IDDispositivo: string, nuovoIdLingua: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
    modificaSerieChiaveDispositivo(IDDispositivo: string, nuovaSerieChiavi: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
    modificaEmailDispositivo(IDDispositivo: string, nuovaEmail: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoEmail>>;
    modificaOperatoriDispositivo(IDDispositivo: string, IDOperatori: string[]): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
    modificaNoteDispositivo(IDDispositivo: string, nuoveNote: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoModificaCampoDispositivo<PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriModificaCampoDispositivoErroriBase>>;
}

interface HubGestioneDispositiviClient {
    notificaTermineAttesaRicezioneBackupDispositivi(uuidOperazionePerNotifica: string): void;
}

interface HubGestioneElementiImportati {
    server: HubGestioneElementiImportatiServer;
    client: HubGestioneElementiImportatiClient;
}

interface HubGestioneElementiImportatiServer {
    rilevaInformazioniGeneraliDocumenti(IDAnagraficheIntestatari: string[], IDOperatori: string[], IDTipiDocumenti: string[], dataDocumentoIniziale: Date, dataDocumentoFinale: Date): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniGeneraliDocumentoConDescrizioni[]>;
    rilevaInformazioniDettagliateDocumento(IDDocumento: string, IDDispositivoOrigineDati: string, dataOrigineDati: Date): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneElementiImportati.DettagliDocumentoConInformazioniCorpo>;
    rilevaOperatori(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniOperatore[]>;
    rilevaAnagrafiche(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniAnagrafica[]>;
    rilevaTipiDocumento(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniTipoDocumento[]>;
    rilevaInformazioniCorpoDocumento(IDDocumento: string, IDDispositivoOrigineDati: string, dataOrigineDati: Date): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneElementiImportati.InformazioniRigaDocumentoConDescrizioni[]>;
}

interface HubGestioneElementiImportatiClient {
}

interface HubGestioneInvitiDispositivi {
    server: HubGestioneInvitiDispositiviServer;
    client: HubGestioneInvitiDispositiviClient;
}

interface HubGestioneInvitiDispositiviServer {
    rilevaInvitiDispositivi(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ModelloInvitoDispositivo[]>;
    confermaInvitoNuovoDispositivo(emailDispositivo: string, operatoriDaAssociare: string[], descrizioneDispositivo: string, IDSerieChiave: string, IDGruppoDispositivi: string, IDLingua: string, note: string, preferito: boolean, nomeFilePerBackup: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.RisultatoOperazioneConErroriSeparati<PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriValidazioneNuovoInvitoDispositivo, PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriPostValidazioneNuovoInvitoDispositivo>>;
    verificaConfigurazioneInvioInviti(): JQueryPromise<boolean>;
}

interface HubGestioneInvitiDispositiviClient {
    notificaElaborazioneInvitoTerminata(): void;
}

interface HubGestioneUtentiEGruppiUtenti {
    server: HubGestioneUtentiEGruppiUtentiServer;
    client: HubGestioneUtentiEGruppiUtentiClient;
}

interface HubGestioneUtentiEGruppiUtentiServer {
    rilevaUtenti(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloUtenteSemplificato[]>;
}

interface HubGestioneUtentiEGruppiUtentiClient {
}

interface HubGestioneInvioERicezioneDati {
    server: HubGestioneInvioERicezioneDatiServer;
    client: HubGestioneInvioERicezioneDatiClient;
}

interface HubGestioneInvioERicezioneDatiServer {
    rilevaDatiElaborazioni(tipoElaborazioni: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.TipiRichiestaElaborazioni, escludiPacchettiAssociatiADispositiviInesistenti: boolean): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiElaborazione[]>;
    aggiornaStatoTrasmissionePacchetto(nomeFilePacchetto: string): JQueryPromise<boolean>;
    rilevaDettagliContenutoPacchetto(nomeFilePacchetto: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloPerDescrizionePacchetto>;
}

interface HubGestioneInvioERicezioneDatiClient {
}

interface HubLogin {
    server: HubLoginServer;
    client: HubLoginClient;
}

interface HubLoginServer {
    login(IDArchivio: string, IDUtente: string, password: string, ricordami: boolean): JQueryPromise<PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin>;
    loginClient(IDArchivio: string, IDUtente: string, password: string): JQueryPromise<PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin>;
    supportaArchiviMultipli(): JQueryPromise<boolean>;
    rilevaInformazioniPerLogin(IDArchivioPerDescrizione: string): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubLogin.InformazioniPerLogin>;
    rilevaUtenteConnesso(): JQueryPromise<PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloDatiSessioneUtenteConnesso>;
    rilevaSeAutenticato(): JQueryPromise<boolean>;
}

interface HubLoginClient {
}

interface ISignalRConnection {
    hub: SignalR.Connection;
    hubConsoleWindows: HubConsoleWindows;
    hubEstensioni: HubEstensioni;
    hubGestioneAttivita: HubGestioneAttivita;
    hubGestioneDispositivi: HubGestioneDispositivi;
    hubGestioneElementiImportati: HubGestioneElementiImportati;
    hubGestioneInvioERicezioneDati: HubGestioneInvioERicezioneDati;
    hubGestioneInvitiDispositivi: HubGestioneInvitiDispositivi;
    hubGestioneUtentiEGruppiUtenti: HubGestioneUtentiEGruppiUtenti;
    hubLogin: HubLogin;
}

declare global {
    interface JQueryStatic {
        connection: ISignalRConnection;
    }
}
